import styled from 'styled-components';
import { AppColors } from '../../StyledComponents/Graphics';

export const ButtonContainer = styled.div`
    display: flex;
    height: auto;
    width: 90%;
`;

export const DragButton = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    right: -7px;
    width: 15px;
    height: 100%;
    cursor: ew-resize;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    user-select: none;
`;

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
`;

export const SidebarContainer = styled.div`
    position: relative;
    height: 100%;
    width: ${({ width }) => width}px;
    max-width: 600px;
    background-color: ${AppColors.MRWHITE};
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;

export const SidebarContent = styled.div`
    display: flex;
    height: 100%;
`;

export const SidebarSection = styled.div`
    flex: 1;
    overflow-y: auto;
    padding: 5px;

    &:not(:last-child) {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    }
`;
