import { useContext, useRef, useState } from "react";
import HistoryReel from "../../Global/Layouts/History/History";
import { StateContext } from "../../Controllers/contexts/StateContext";
import Loading from "../../Global/Components/LoadingSpinner";
import styled from "styled-components";
import { Heading, StyledButton, SubmitButton } from "../../Global/StyledComponents/UserFormStyledComponents";
import { AppColors } from "../../Global/StyledComponents/Graphics";
import Sidebar from "../../Global/Layouts/SideBar/SideBar";
import InputMenu from "../../Global/Layouts/InputMenu/InputMenu";
import { UndoProvider } from "../../Controllers/contexts/UndoContext";
import { BlankSpacer } from "../../Global/StyledComponents/AppStyledComponents";
import { DEFAULT_INPUTS } from "../../Global/constants";
import DropdownMenu from "../../Global/Components/DropdownMenu";
import SearchDropdown from "../../Global/Components/SearchDropdown";
import TickerInputArea from "../../Global/Components/TickerInputArea";
import { StyledLabel } from "../../Global/StyledComponents/UserFormStyledComponents";
import supabase from "../../Controllers/services/supabaseClient";
import AssetCheck from "../../Controllers/utils/assetCheck";

const CoverContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

const PortfolioContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const HistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const WatchlistAnalyzer = () => {
    const {
        analysisFailed,
        analyzing,
        assets,
        assetsLoading,
        etfAssets,
        etfsLoading,
        etfsWithHoldings,
        history,
        selectedWatchlist,
        setInputs,
        setShowHistory,
        setSelectedWatchlist,
        showHistory,
        translationFailed,
        watchlists,
        watchlistsLoading,
        assetKeys,
        assetNames,
        assetTickers,
    } = useContext(StateContext);
    const [clearEtfSearch, setClearEtfSearch] = useState(false);
    const [clearAssetSearch, setClearAssetSearch] = useState(false);
    const menuContainerRef = useRef(null);
    const [tickerArray, setTickerArray] = useState([]);

    const handleToggleHistory = () => {
        setShowHistory(prevState => !prevState);
    };

    const handleCreateNewAnalysis = () => {
        setShowHistory(false);
        setInputs(DEFAULT_INPUTS);
        setSelectedWatchlist(null);
    };

    const handleSelectWatchList = (e, value) => {
        const watchlist = value ?? e.target.value;
        setClearEtfSearch(true);
        setClearAssetSearch(true);
        if (!watchlist) {
            setSelectedWatchlist(null);
            setInputs(prevState => ({
                ...prevState,
                assets: [],
            }));
        } else {
            const parsedWatchlist = JSON.parse(watchlist);
            setSelectedWatchlist(parsedWatchlist);
                       setInputs(prevState => ({
                ...prevState,
                assets: [
                    ...parsedWatchlist?.data
                ],
            }));
        }
    };

    const handleEtfSelect = async (value) => {
        const res = await supabase.rpc('get_etf_tickers', {
            ticker: value,
        });

        const constituents = res?.data?.map(item => item.symbol);

        setClearAssetSearch(true);
        setSelectedWatchlist(null);
        setInputs(prevState => ({
            ...prevState,
            assets: constituents
        }));
    };

    const handleAssetSelect = (value) => {
        setClearEtfSearch(true);
        setSelectedWatchlist(null);
        setInputs(prevState => ({
            ...prevState,
            assets: [value]
        }));
    };

    const handleAddTickers = (values) => {
        const filterTickerArray = (array) => {
            const filteredArray = array?.filter(ticker =>
                AssetCheck({
                    assetKeys,
                    assetNames,
                    assetTickers,
                    handleSubmit: () => {},
                    inputVal: ticker,
                }) &&
                ticker !== ""
            ).map(ticker => ticker.toUpperCase());

            return filteredArray;
        };

        const tickers = filterTickerArray(values);
        
        setInputs(prevState => ({
            ...prevState,
            assets: [...prevState.assets, ...tickers]
        }));
    };

    return (
        <>
            {/* <Sidebar /> */}
            <PortfolioContainer>
                    <div style={{ position: 'absolute', top: 0, left: 10 }}>
                        {Object.keys(history?.data).length && !showHistory ? 
                            <StyledButton onClick={handleToggleHistory}>
                                Back to History
                            </StyledButton> :
                            showHistory ?
                                <StyledButton onClick={handleToggleHistory}>
                                    Back to Inputs
                                </StyledButton> : null
                        }
                    </div>
                {Object.keys(history?.data).length && showHistory ?
                    <HistoryWrapper >
                        <HistoryReel history={history} />
                        <BlankSpacer />
                        <SubmitButton onClick={handleCreateNewAnalysis} style={{ width: '50%' }}>
                            Create New Analysis
                        </SubmitButton>
                        <BlankSpacer />
                    </HistoryWrapper> :
                    <UndoProvider>
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            ref={menuContainerRef}
                        >

                            <InputMenu
                                clearEtfSearch={clearEtfSearch}
                                clearAssetSearch={clearAssetSearch}
                                setClearEtfSearch={setClearEtfSearch}
                                setClearAssetSearch={setClearAssetSearch}
                                menuContainerRef={menuContainerRef}
                            >
                                {!watchlistsLoading &&
                                    <DropdownMenu
                                        handleChange={handleSelectWatchList}
                                        id='watchlists'
                                        label='Select A Watchlist'
                                        options={watchlists}
                                        value={selectedWatchlist}
                                        flexNumber={2}
                                    />
                                }
                                {!assetsLoading && !etfsLoading &&
                                    <div
                                        flexNumber={2}
                                    >
                                        <StyledLabel>
                                            Select ETF:
                                        </StyledLabel>
                                        <SearchDropdown
                                            clearSearch={clearEtfSearch}
                                            handleSubmit={handleEtfSelect}
                                            options={etfAssets.filter(asset => etfsWithHoldings.includes(asset.symbol))}
                                            position='bottom'
                                            setClearSearch={setClearEtfSearch}
                                            dropdownContainerRef={menuContainerRef}
                                        />
                                    </div>
                                }
                                <TickerInputArea
                                    handleSubmit={handleAddTickers}
                                    setTickerArray={setTickerArray}
                                    tickerArray={tickerArray}
                                />
                            </InputMenu>
                        </div>
                    </UndoProvider>
                }
                {analyzing &&
                    <CoverContainer>
                        <Loading />
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            Analyzing
                        </Heading>
                    </CoverContainer>
                }
                {analysisFailed &&
                    <CoverContainer>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            Analysis Failed. Please try again.
                        </Heading>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            If this continues please contact us at <a style={{ fontSize: 18}} href = "mailto: support@marketreader.com">support@marketreader.com</a>.
                        </Heading>
                    </CoverContainer>
                }
                {translationFailed &&
                    <CoverContainer>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            Translation Failed. Please try again.
                        </Heading>
                        <Heading style={{ color: AppColors.MRWHITE }}>
                            If this continues please contact us at <a style={{ fontSize: 18}} href = "mailto: support@marketreader.com">support@marketreader.com</a>.
                        </Heading>
                    </CoverContainer>
                }
            </PortfolioContainer>
        </>
    )
};

export default WatchlistAnalyzer;
