export const DEFAULT_INPUTS = {
    assets: [],
    bias: 'neutral',
    output_length: "paragraph",
};

export const Languages = {
    label: 'Select Language',
    values: [
        {
            label: 'German',
            value: 'de'
        },
        {
            label: 'Danish',
            value: 'da'
        },
        {
            label: 'Japanese',
            value: 'jp'
        },
        {
            label: 'Portuguese',
            value: 'pt'
        },
        {
            label: 'Spanish',
            value: 'es'
        },
    ],
};

export const LanguageMap = {
    de: 'German',
    da: 'Danish',
    jp: 'Japanese',
    pt: 'Portuguese',
    es: 'Spanish',
};

export const Periods = {
    label: 'Period',
    values: [
        {
            label: '',
            value: null,
        },
        {
            label: 'Past Week',
            value: 'past-week'
        },
        {
            label: 'Week to Date ',
            value: 'week-to-date'
        },
        {
            label: 'Past Month',
            value: 'past-month'
        },
        {
            label: 'Month to Date',
            value: 'month-to-date'
        },
        {
            label: 'Past Quarter',
            value: 'past-quarter'
        },
        {
            label: 'Quarter to Date',
            value: 'quarter-to-date'
        },
        {
            label: 'Year to Date',
            value: 'year-to-date'
        },
    ],
};
