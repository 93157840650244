import { createGlobalStyle } from "styled-components";
import { AppColors } from "./Graphics";

export const GlobalStyles = createGlobalStyle`

   a {
      color: ${AppColors.MRGREEN};
      text-decoration: inherit;
      font-size: 12px;
   }

   body {
      color: ${AppColors.MRNAVY};
      margin: 0px;
      padding: 0px;
      display: flex;
      flex-direction: column;
      line-height: inherit;
      background-color: ${AppColors.MRWHITE};
      font-family: Arial, Helvetica, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow: hidden;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
         display: none;
         overflow: hidden;
      }
   }

   button {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 10px;
      background-color: ${AppColors.MRBLUE };
      border: 1px solid ${AppColors.MRNAVY40 };
      color: ${AppColors.MRWHITE};
      cursor: pointer;
      :hover{
         background-color: ${AppColors.MRRED };
         border: 2px solid ${AppColors.MRWHITE};
      }
   }

   button, input, optgroup, select, textarea {
      text-transform: none;
      margin: 0; 
      padding: 10px;
   }

   div {
      display: block;
   }

   *,
   ::before,
   ::after {
      box-sizing: border-box;
   }

   h1, h2, h3, h4, h5, h6 {
      margin-block-start: 0px;
      margin-block-end: 0px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      text-align: left;
      margin: 0px;
      padding: 0px;
      color: ${AppColors.MRWHITE};
   }

   h1 {
      font-size: 20px;
      line-height: 28px;
      margin:5px;
      font-family: 'Poppins', Arial, Helvetica, sans-serif;
      font-weight: 600;
   }

   h2 {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      margin:15px;
      font-family: 'Poppins', Arial, Helvetica, sans-serif;
      font-weight: 600;
   }

   h3 {
      font-size: 16px;
      line-height: 24px;
      margin:10px;
      font-weight: 500;
      font-family: 'Poppins', Arial, Helvetica, sans-serif;
   }

   h4 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin:15px;
      padding: 10px;
      font-family: 'Poppins', Arial, Helvetica, sans-serif;
   }

   html {
      -webkit-text-size-adjust: 100%;
      font-family: Mulish,sans-serif;
      line-height: 1.5;
      tab-size: 3;
   }

   img {
      max-width: 99%;
   }

   p {
      font-size: 14px;
      line-height: 24px;
      font-family: Arial, Helvetica, sans-serif;
      margin: 0 0 1em 0;
      padding: 0px;
   }

   :root {
      color-scheme: light;
   }
`;
