import styled from "styled-components";
import { StyledLink } from "../../../StyledComponents/UserFormStyledComponents";
import { MenuContainer } from "../styledComponents";
import Dashboard from '../../../../Pages/Dashboard/Dashboard';
import { StateContext } from "../../../../Controllers/contexts/StateContext";
import { useContext } from "react";

const NavSpacer = styled.div`
    padding: 5px 0;
`;

const PagesMenu = () => {
    const {
        setInputs,
        setShowHistory,
        setSelectedWatchlist,
        setSelectedPortfolio,
    } = useContext(StateContext);

    const resetInputs = () => {
        setInputs(prevState => ({
            ...prevState,
            assets: [],
        }));
        setShowHistory(false);
        setSelectedWatchlist(null);
        setSelectedPortfolio(null);
    };

    return (
        <MenuContainer>
            {/* <NavSpacer>
                <StyledLink to='/'>
                    Home
                </StyledLink>
            </NavSpacer> */}
            <NavSpacer>
                <StyledLink 
                    to='/portfolio'
                    onClick={resetInputs}
                >
                    Portfolio Analyzer
                </StyledLink>
            </NavSpacer>
            <NavSpacer>
                <StyledLink 
                    to='/'
                    onClick={resetInputs}
                >
                    Watchlist Analyzer
                </StyledLink>
            </NavSpacer>
        </MenuContainer>
    )
};

export default PagesMenu;
