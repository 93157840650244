import styled from 'styled-components';
import { AppColors } from '../../StyledComponents/Graphics';
import { checkAndUpdateHistory, hexToRgba } from '../../../Controllers/utils';
import { StyledButton, StyledLabel, SubmitButton } from '../../StyledComponents/UserFormStyledComponents';
import DropdownMenu from '../../Components/DropdownMenu';
import { LanguageMap, Languages } from '../../constants';
import supabase from '../../../Controllers/services/supabaseClient';
import { useContext, useEffect, useRef, useState } from 'react';
import { StateContext } from '../../../Controllers/contexts/StateContext';
import { AuthContext } from '../../../Controllers/contexts/AuthContext';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { AssetView } from '../InputMenu/InputMenu';
import { StyledValue } from '../InputMenu/InputMenu';
import { BlankSpacer } from '../../StyledComponents/AppStyledComponents';

const TranslationContainer = styled.div`
	width: 30%;
	display: flex;
	justify-content: end;
	align-items: end;
`;

const LanguageContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const HistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 16px;
    background-color: ${AppColors.MRWHITE};
    align-items: center;
	width: 100%;
`;

const HistoryItem = styled.div`
    background-color: ${AppColors.MRWHITE};
    border: 1px solid ${hexToRgba(AppColors.MRGREY, 0.25)};
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 75%;
`;

const InputList = styled.div`
    columns: 3;
    justify-content: space-around;
    background-color: ${hexToRgba(AppColors.MRGREEN, 0.25)};
    border-left: 4px solid ${AppColors.MRGREEN};
    border-radius: 10px;
    list-style-type: none;
    padding: 12px;
    padding-left: 20px;
    margin-bottom: 16px;
`;

const Output = styled.div`
    padding: 12px;
    color: ${AppColors.MRBLACK};
    margin-bottom: 8px;

    p {
        margin-bottom: 1em;
		font-size: 18px;
    }
`;

export const StyledList = styled.div`
    padding: 0;
    margin: 0;
	columns: ${({ columns, total }) => columns ? columns : (total / 5) > 2 ? 5 : 3};
`;

export const StyledListItem = styled.div`
    padding: 3px;
    display: flex;
    align-items: center;
`;

const Timestamp = styled.div`
    font-size: 14px;
	font-weight: 600;
    color: ${AppColors.MRGREY};
    text-align: right;
`;

const HistoryReel = ({ history }) => {
	const historyContainerRef = useRef(null)
	const [language, setLanguage] = useState('de');
	const {
		logError,
		setAnalyzing,
		setTranslationFailed,
		setHistory,
		setInputs,
		setShowHistory,
	} = useContext(StateContext);

	const { user } = useContext(AuthContext);

	const handleEditInput = (item) => {
		setShowHistory(false);
		setInputs(item?.inputs);
	};

	const handleSelectLanguage = (e) => {
		const { value } = e.target;
		setLanguage(value);
	};

	const handleTranslate = async ({ historyItem, retry = false }) => {
		setAnalyzing(true);

		const res = await supabase.functions.invoke('translateAnalysis', {
			body: {
				to_be_translated: historyItem.output,
				input_type: 'text',
				languages: [language]
			}
		});

		const { data, error } = res;

		if (error) {

			if (retry) {
				handleTranslate({ historyItem, retry: false });

				return;
			}
			logError(error);
		}

		const { msg, translation} = JSON.parse(data);

		if (msg) {
			handleTranslate({ historyItem, retry: false });

			return;
		}

		if (translation) {

			setHistory(prevState => {
				const dataObj = checkAndUpdateHistory(prevState.data, {
					inputs: {
						...historyItem.inputs,
						language: language,
					},
					output: JSON.parse(translation?.message?.text)[language],
				});
	
				// const saveHistory = ({ retry = false }) => {
				// 	supabase.from('user_preferences').upsert({
				// 		...prevState,
				// 		data: dataObj,
				// 		type: 'history',
				// 		user_id: user?.id,
				// 	}).eq('type', 'history').then((res) => {
				// 		if (res?.error) {
				// 			const error = new Error(res?.error?.message);
				// 			logError(error.message, undefined, error);
		
				// 			if (retry) {
				// 				saveHistory({ retry: false });
				// 			}
				// 		}
				// 	});
				// };
	
				// saveHistory({ retry: true });
				setAnalyzing(false);
				
				return ({
					...prevState,
					data: dataObj,
					// type: 'history',
					// user_id: user?.id,
				});
			});
		} else {
			setTranslationFailed(true);
			setAnalyzing(false);
			setTimeout(() => {
                setTranslationFailed(false);
            }, 3000);
		}
	};

	const scrollToBottom = () => {
        if (historyContainerRef.current) {
            historyContainerRef.current.scrollTop = historyContainerRef.current.scrollHeight;
        }
    };

	useEffect(() => {
		scrollToBottom();
	}, [history]);

    return (
        <HistoryContainer ref={historyContainerRef}>
            {Object.entries(history?.data).map(([timestamp, item]) => (
                <HistoryItem key={timestamp}>
                    <InputList>
						{item?.inputs?.macro_analysis?.length ?
							<>
								<StyledLabel>
									Macro Analysis:
								</StyledLabel>
								<StyledList columns={2} >
									{item.inputs?.macro_analysis?.map((item, index) => (
										<StyledListItem key={index}>
											<StyledValue>
												{item}
											</StyledValue>
										</StyledListItem>
									))}
								</StyledList>
							</> : null
						}
						<StyledLabel style={{ marginTop: 0 }}>
							Start Date:&nbsp;
							<StyledValue>{item?.inputs?.date_start}</StyledValue>
						</StyledLabel>
						<StyledLabel>
							End Date:&nbsp;
							<StyledValue>{item?.inputs?.date_end}</StyledValue>
						</StyledLabel>
						<StyledLabel>
							Output Length:&nbsp;
							<StyledValue>{item?.inputs?.output_length}</StyledValue>
						</StyledLabel>
						{item?.inputs?.benchmark_asset &&
							<StyledLabel>
								Benchmark:&nbsp;
								<StyledValue>{item?.inputs?.benchmark_asset}</StyledValue>
							</StyledLabel>
						}
						{item?.inputs?.assets?.length &&
								<StyledLabel>
									Assets:
									<div style={{ padding: 1 }}>
										<AssetView total={item?.inputs?.assets?.length}>
											{item?.inputs?.assets?.map((ticker, index) => (
												<StyledListItem key={index}>
													<StyledValue>
														{ticker}
													</StyledValue>
												</StyledListItem>
											))}
										</AssetView>
									</div>
								</StyledLabel>
						}
                    </InputList>
                    <Output>
						<Markdown remarkPlugins={[remarkGfm]}>
							{item?.output}
						</Markdown>
						<Timestamp>{new Date(Number(timestamp)).toLocaleString()}</Timestamp>
					</Output>
					<BlankSpacer />
					<LanguageContainer>
		
						{item?.inputs?.language ?
							<StyledLabel>
								Language:&nbsp;
								<b>{LanguageMap[item?.inputs?.language]}</b>
							</StyledLabel> :
							// <TranslationContainer>
							// 	{/* <DropdownMenu
							// 		id='period'
							// 		label={Languages.label}
							// 		handleChange={handleSelectLanguage}
							// 		options={Languages.values}
							// 	/> */}
							// </TranslationContainer>
								<StyledButton
									onClick={() => handleTranslate({ historyItem: item, retry: true })}
									style={{ margin: '1px 0 1px 10px' }}
								>
									Translate
								</StyledButton>
						}
						<SubmitButton
							style={{ marginTop: 'auto', width: 'auto', padding: '10px 20px' }}
							onClick={() => handleEditInput(item)}
						>
							Edit Parameters
						</SubmitButton>
					</LanguageContainer>
                </HistoryItem>
            ))}
        </HistoryContainer>
    );
};

export default HistoryReel;
